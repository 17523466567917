
import { defineComponent, ref, onMounted } from "vue";
import { MBDatatable } from "magicbean-saas-common";
import FilterDropdwon from "@/views/documents/report/FilterDropdown.vue";
import { MenuComponent } from "@/assets/ts/components";
import { ApiDocuments } from "@/core/api";
import moment from "moment";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import mixin from "@/mixins";
import fileDownload from "js-file-download";
import { setModuleBCN } from "@/core/directive/function/common";
import { useRoute } from "vue-router";

interface Invoice {
  created_at: string;
  invoice_created_at: string;
  type: string;
  currency: string;
  downloadDisabled: boolean;
}

interface SortOption {
  field: string;
  direction: string;
}

interface FilterOption {
  field: string;
  value: string | Array<string>;
  from?: string;
  to?: number;
  condition?: string;
}

export default defineComponent({
  name: "settlement-report",
  components: {
    MBDatatable,
    FilterDropdwon,
  },
  setup() {
    const { t } = useI18n();
    const { showServerErrorMsg } = mixin();
    const route = useRoute();

    const loading = ref(true);
    const tableData = ref<Array<Invoice>>([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(10);
    const search = ref<string>("");
    const disabledExportSelected = ref(false);
    const disabledExportAll = ref(false);
    const checkedInvoice = ref<Array<number>>([]);
    const sortOptions = ref<Array<SortOption>>([
      { field: "created_at", direction: "desc" },
    ]);
    const filterOptions = ref<Array<FilterOption>>([]);
    const tableHeader = ref([
      {
        name: t("documents.type"),
        key: "type",
        sortable: false,
      },
      {
        name: t("documents.creationDate"),
        key: "created_at",
        sortable: true,
      },
      {
        name: t("documents.dataRange"),
        key: "invoice_created_at",
        sortable: true,
      },
      {
        name: t("documents.currency"),
        key: "currency",
        sortable: false,
      },
      {
        name: t("documents.download"),
        key: "actions",
        sortable: false,
      },
    ]);

    onMounted(() => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route);
      getSettlementInvoiceReportList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    });

    const getSettlementInvoiceReportList = (
      page: number,
      pageSize: number,
      search?: string,
      sortOptions?: Array<SortOption>,
      filterOptions?: Array<FilterOption>
    ) => {
      loading.value = true;
      ApiDocuments.getInvoiceReportList({
        page: page,
        page_size: pageSize,
        search_value: search,
        sort_orders: sortOptions,
        filter_group: filterOptions,
      })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            tableData.value.splice(
              0,
              tableData.value.length,
              ...data.data.items
            );
            tableData.value.forEach((item) => {
              item.downloadDisabled = false;
            });
            total.value = data.data.total;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const onRowsSelectChange = (selection) => {
      let ids: number[] = [];
      selection.forEach((item) => {
        ids.push(item.id);
      });
      checkedInvoice.value = ids;
    };

    const filesExport = (isAll = false) => {
      let idParams = {};
      if (isAll === true) {
        disabledExportAll.value = true;
      } else {
        if (checkedInvoice.value.length > 0) {
          idParams = {
            id: checkedInvoice.value,
          };
        } else {
          return false;
        }
        disabledExportSelected.value = true;
      }
      ApiDocuments.getInvoiceDownloadAllCSV({
        search_value: search.value,
        filter_group: filterOptions.value,
        sort_orders: sortOptions.value,
        page: currentPage.value,
        page_size: pageSize.value,
        ...idParams,
      })
        .then(({ data }) => {
          if (isAll === true) {
            disabledExportAll.value = false;
          } else {
            disabledExportSelected.value = false;
          }
          let fileReader = new FileReader();
          fileReader.onload = function () {
            try {
              // 说明是普通对象数据，后台转换失败
              const jsonData = JSON.parse(this.result as any);
              showServerErrorMsg(jsonData);
            } catch (err) {
              // 解析成对象失败，说明是正常的文件流
              // const disposition = data.headers["content-disposition"];
              // fileDownload(data.data, disposition.split("filename=")[1]);
              downLoadBlobFile(data, `report-${new Date().getTime()}.zip`);
            }
          };
          fileReader.readAsText(data);
        })
        .catch((error) => {
          if (isAll === true) {
            disabledExportAll.value = false;
          } else {
            disabledExportSelected.value = false;
          }
          console.log(error);
        });
    };

    const fileExport = (id, item) => {
      item.downloadDisabled = true;
      ApiDocuments.getInvoiceDownloadCSV({
        id: id,
      })
        .then(({ data }) => {
          // console.log(data.headers["content-disposition"]);
          item.downloadDisabled = false;
          let fileReader = new FileReader();
          fileReader.onload = function () {
            try {
              // 说明是普通对象数据，后台转换失败
              const jsonData = JSON.parse(this.result as any);
              showServerErrorMsg(jsonData);
            } catch (err) {
              // 解析成对象失败，说明是正常的文件流
              // const disposition = data.headers["content-disposition"];
              // fileDownload(data.data, disposition.split("filename=")[1]);
              downLoadBlobFile(data, item.file_path);
            }
          };
          fileReader.readAsText(data);
        })
        .catch((error) => {
          item.downloadDisabled = false;
          console.log(error);
        });
    };

    const downLoadBlobFile = (blob, filePath) => {
      const fileName = getFileName(filePath);
      fileDownload(blob, fileName);
    };

    const getFileName = (path) => {
      const index = path.lastIndexOf("/");
      return path.substring(index + 1);
    };

    const formatDate = (date) => {
      return date ? moment(date).utcOffset(2).format("YYYY-MM-DD") : "";
    };

    const formatUtcDate = (date) => {
      return date ? moment(date).utcOffset(0).format("YYYY-MM-DD") : "";
    };

    const addDate = (time) => {
      //加一天
      return moment(moment(time)).add(1, "days").format("YYYY-MM-DD");
    };

    const handleFilter = (val) => {
      let filterArr: Array<FilterOption> = [];
      const DataEnums = {
        start_date: { field: "created_at", condition: "gteq" },
        end_date: { field: "created_at", condition: "lteq" },
        type: { field: "type", condition: "eq" },
      };
      for (let item in val) {
        if (val[item] !== "") {
          if (item === "end_date") {
            filterArr.push({
              field: DataEnums[item].field,
              value: val[item],
              condition: DataEnums[item].condition,
            });
          } else {
            filterArr.push({
              field: DataEnums[item].field,
              value: val[item],
              condition: DataEnums[item].condition,
            });
          }
        }
      }
      filterOptions.value.splice(0, filterOptions.value.length, ...filterArr);
      getSettlementInvoiceReportList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const handleFilterReset = () => {
      resetFilter();
      getSettlementInvoiceReportList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const resetFilter = () => {
      filterOptions.value.splice(0, filterOptions.value.length);
    };

    const onColumnSort = (val) => {
      let sortObj: SortOption = {
        field: val.columnName,
        direction: val.order,
      };
      sortOptions.value.splice(0, sortOptions.value.length, sortObj);
      getSettlementInvoiceReportList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const updateList = () => {
      getSettlementInvoiceReportList(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const onCurrentPageChange = (page) => {
      currentPage.value = page;
      getSettlementInvoiceReportList(
        page,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const onRowsPerPageChange = (pagesize) => {
      pageSize.value = pagesize;
      getSettlementInvoiceReportList(
        currentPage.value,
        pagesize,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const debounceSearch = _.debounce(getSettlementInvoiceReportList, 1000);

    const searchItems = () => {
      debounceSearch(
        currentPage.value,
        pageSize.value,
        search.value,
        sortOptions.value,
        filterOptions.value
      );
    };

    const removeSearch = () => {
      search.value = "";
      updateList();
    };

    return {
      t,
      loading,
      tableHeader,
      tableData,
      currentPage,
      total,
      pageSize,
      search,
      disabledExportAll,
      disabledExportSelected,
      checkedInvoice,
      getSettlementInvoiceReportList,
      onCurrentPageChange,
      onRowsPerPageChange,
      searchItems,
      removeSearch,
      onColumnSort,
      handleFilter,
      handleFilterReset,
      onRowsSelectChange,
      filesExport,
      fileExport,
      formatDate,
      formatUtcDate,
    };
  },
});
